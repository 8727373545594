import { useFormContext } from "react-hook-form";
import useDeviceSize from "../../hooks/useDeviceSize";
import FormTable from "./FormTable";
import { AccompanyTitleWrap } from "../pages/application/ApplicationForm";
import useApplicationFormUitils from "../../hooks/useApplicationFormUitils";
import GetInput from "../molecules/GetInput";
import React from "react";
import { useSelector } from "react-redux";
import FixedFormTable from "./FixedFormTable";

const RenderFormTable = ({ fieldArray, fixedForm, tableCnt, formType, startDate }) => {
    const form = useFormContext();
    const { isMobile } = useDeviceSize();
	const customersInfo = useSelector(state => state.customers).value;

    const { 
        getCategoryDescription, 
		getRegistrationTitle, 
		checkErrorCategory,
    } = useApplicationFormUitils()

    let userCnt = 0;
    /**
     * 동반가족 삭제
     * @param {number} idx 삭제할 필드 고유 idx
     */
    const handleClickDeleteFamily = (id) => {
        const findIdx = fieldArray.fields.findIndex(item => item.id == id);
        // 신청 정보 있는 동반 가족은 삭제 X
        if (fieldArray.fields[findIdx].application_idx) {
            form.setValue(`items3.${findIdx}.fixed_form.is_join`, "불참")
        } else {
            fieldArray.remove(findIdx);
            userCnt--;
        }
    }

    const getFieldSettings = (currentIdx, fieldEl, formEl) => {
        let nextIdx = currentIdx + 1;
        let fieldName;
        let isJoinFieldName; // 참여 여부 필드명
        let siblingFieldName; // 같은 행 필드명
        let use = true;
        switch (fieldEl.type) {
            case 1: // 주사업자
                fieldName =  "items.0.fixed_form";
                isJoinFieldName = fieldName + ".is_join";
                siblingFieldName = "items.1.fixed_form";
                formEl.used_in_items1 ? use = true : use = false;
                break;
            case 2: // 부사업자
                fieldName =  "items.1.fixed_form";
                isJoinFieldName = fieldName + ".is_join";
                siblingFieldName = "items.0.fixed_form";
                formEl.used_in_items2 ? use = true : use = false;
                break;
            case 3: // 동반가족 of 참가자
                let field = "items3";
                if (formType == 3) {
                    field = "items";
                }
                fieldName =  field + "."+ currentIdx + ".fixed_form";
                isJoinFieldName = "show_accompany_family.is_join";
                if (fieldArray.fields[nextIdx]) {
                    siblingFieldName = field + "."+ nextIdx + ".fixed_form";
                }
                break;
        }
        if (formType == 3) {
            use = formEl.is_show == "true"
        }

        return { fieldName, isJoinFieldName, siblingFieldName, use }
    }

    if (isMobile) {
        return fieldArray.fields.map((fieldEl, fieldIdx) => {
            const currentIdx = fieldArray.fields.findIndex(item => item.id === fieldEl.id);
            return (
                <FormTable key={`accompany_table_mo_${fieldEl.id}`}>
                    <colgroup>
                        <col style={{width: "140px"}} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan={2}>
                                <AccompanyTitleWrap>
                                    {
                                        (fieldEl.type === 3 && formType !== 3)
                                        ?
                                        <>
                                            <input type="hidden" {...form.register(`items3.${currentIdx}.type_text`)} value={`동반가족 ${currentIdx+1}`} />
                                            동반가족{currentIdx+1}
                                        </>
                                        :
                                        fieldEl.type_text
                                    }

                                    {
                                    (fieldArray.fields.length > 1 && fieldEl.type === 3) && 
                                        <div className="btn-delete-family" onClick={() => {
                                            handleClickDeleteFamily(fieldEl.id);
                                        }}>
                                            <img src={process.env.PUBLIC_URL + "/image/btn_delete_accompany_family.svg"}/>
                                        </div>
                                    }
                                </AccompanyTitleWrap>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fixedForm?.map((item, idx) => {
                                const { fieldName, isJoinFieldName, use } = getFieldSettings(currentIdx, fieldEl, item);

                                if (use) {
                                    const description = getCategoryDescription(item.name);
                                    let isColumn = false;
                                    // th와 td가 두 줄로 노출되는 항목
                                    if (item.type == "file" || item.name == "allergy" || item.name.includes("custom")) {
                                        isColumn = true;
                                    }

                                    const title = item.name == "registration_number" 
                                                ? 
                                                getRegistrationTitle(form.watch(`${fieldName}.nationality_type`), "",) ? getRegistrationTitle(form.watch(`${fieldName}.nationality_type`), "",) : "주민등록번호"
                                                : 
                                                item.title
                                    let input = <GetInput
                                                    type={item.type} 
                                                    name={item.name} 
                                                    uniqueName={fieldName} 
                                                    option={JSON.stringify(item.options)} 
                                                    isRequired={item.is_required == "true"} 
                                                    disabled={(form.watch(isJoinFieldName) == "불참" && item.name != "is_join") ? true : undefined}
                                                    startDate={startDate}
                                                />
                                            

                                    if (fieldEl.type !== 3 && item.name == "abo_name") {
                                        if (fieldEl.type === 1) {
                                            input = customersInfo[0].name;
                                        }
                                        else if (fieldEl.type === 2 && customersInfo.length == 2) {
                                            input = customersInfo[1]?.name;
                                        }
                                    } else if (item.name == "abo_number") {
                                        input = customersInfo[0].uid;
                                    }

                                    // th와 td가 두 줄로 노출되는 항목이거나 설명이 있는 항목
                                    if (isColumn || description) {
                                        return (
                                            <React.Fragment key={`mo_accompany_table_tr_${fieldIdx}_${item.name}`}>
                                                {
                                                    isColumn ? 
                                                    <>
                                                        <tr className="border-none">
                                                            <th className={item.is_required == "true" ? "required" : ""} colSpan={2}>
                                                                <span>
                                                                    {title}
                                                                </span>	
                                                            </th>
                                                        </tr>
                                                        <tr className={description?"border-none":""}>
                                                            <td colSpan={2}>
                                                                {input}
                                                            </td>
                                                        </tr>
                                                    </>
                                                    : 
                                                    <tr className={description?"border-none":""}>
                                                        <th className={item.is_required ? "required" : ""}>
                                                            <span>
                                                                {title}
                                                            </span>	
                                                        </th>
                                                        <td>
                                                            {input}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    description &&
                                                    <tr className="desc">
                                                        <td colSpan={2}>
                                                            {description}
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <tr key={`mo_accompany_table_tr_${fieldIdx}_${item.name}`}>
                                                <th className={item.is_required ? "required" : ""}>
                                                    <span>
                                                        {title}
                                                    </span>	
                                                </th>
                                                <td>
                                                    {input}
                                                </td>
                                            </tr>
                                        )
                                    }
                                }
                            })
                        }
                    </tbody>
                </FormTable>
            )
        })	
    } else {
        return (
            Array(tableCnt).fill(0).map((tableEl, tableIdx) => {
                let formCount = [];
                // 기본적으로 각 테이블에는 2개의 폼을 할당
                let max = 2;
                let trJsx = ''; // 화면 깜빡임 방지
                
                // 마지막 테이블에 남은 폼을 할당
                if (tableIdx === tableCnt - 1) {
                    let remainingForms = fieldArray.fields.length % 2;
                    max = remainingForms === 0 ? 2 : remainingForms;
                }
    
                // 최대 2명씩 배열에 추가
                for (let i = 0; i < max; i++) {
                    if (fieldArray.fields[userCnt]) {
                        formCount.push(fieldArray.fields[userCnt]);
                        userCnt++;
                    }
                }
    
                trJsx = fixedForm?.map(formEl => { // 항목  
                    const isRequired = formEl.is_required == "true";
                    let align = "";
                    return (
                        <tr key={`accompany_tbody_tr_${formEl.name}`}>
                            {formCount.map((fieldEl, fieldIdx) => {
                                const currentIdx = fieldArray.fields.findIndex(item => item.id === fieldEl.id);
                                const { fieldName, isJoinFieldName, siblingFieldName, use } = getFieldSettings(currentIdx, fieldEl, formEl);

                                let input = <GetInput  
                                                type={formEl.type} 
                                                name={formEl.name} 
                                                uniqueName={`${fieldName}`} 
                                                isRequired={isRequired} 
                                                disabled={(form.watch(isJoinFieldName) === "불참" && formEl.name !== "is_join") ? true : undefined}
                                                option={JSON.stringify(formEl.options)}
                                                startDate={startDate}
                                        />;

                                // 오류 메시지 있는 경우 vertical-align: top;
                                if (siblingFieldName) {
                                    align = checkErrorCategory(fieldName, siblingFieldName, formEl.name) ? "top" : "";
                                }

                                if (fieldEl.type !== 3 && formEl.name == "abo_name") {
                                    if (fieldEl.type === 1) {
                                        input = customersInfo[0].name;
                                    }
                                    else if (fieldEl.type === 2 && customersInfo.length == 2) {
                                        input = customersInfo[1]?.name;
                                    }
                                } else if (formEl.name == "abo_number") {
                                    input = customersInfo[0].uid;
                                }

                                return (
                                    <React.Fragment key={`accompany_tbody_tr_items_${fieldEl.id}`}>
                                        {
                                            fieldIdx == 0 &&
                                            <th key={`accompany_th_${fieldEl.id}`} className={ isRequired ? "required" : "" }>
                                                <span>
                                                    {
                                                    formEl.name == "registration_number" 
                                                    ?
                                                        getRegistrationTitle(form.watch(fieldName+ ".nationality_type"), form.watch(siblingFieldName+ ".nationality_type")) 
                                                    :
                                                        formEl.title
                                                    }
                                                </span>
                                                {getCategoryDescription(formEl.name)}
                                            </th>
                                        }
    
                                        <td key={`accompany_td_${fieldEl.id}`} className={align}>
                                            {use && input}
                                        </td>
                                    </React.Fragment>
                                )
                            })}
                        </tr>
                    )
                })
    
                if (trJsx) {
                    return (
                    <FixedFormTable key={`accompany_table_${tableIdx}`}>
                        <thead>
                            <tr>
                                <td></td>
                                {
                                    // 인원 수 만큼 반복문 
                                    formCount.map((el) => {
                                        const currentIdx = fieldArray.fields.findIndex(item => item.id === el.id);
                                        return (
                                            <th key={`accompany_th_${el.id}`}>
                                                <AccompanyTitleWrap>
                                                    {
                                                        (el.type === 3 && formType !== 3)
                                                        ?
                                                        <>
                                                            <input type="hidden" {...form.register(`items3.${currentIdx}.type_text`)} value={`동반가족${currentIdx+1}`} />
                                                            동반가족{currentIdx+1}
                                                        </>
                                                        :
                                                        el.type_text
                                                    }
                                                    {
                                                    // 동반가족 2명 이상일 경우 X 버튼 노출
                                                    // 신청 정보 있는 동반가족은 삭제 불가
                                                    (fieldArray.fields.length > 1 && el.type === 3 && !el.application_idx) &&
                                                    <div className="btn-delete-family" onClick={() => {
                                                        handleClickDeleteFamily(el.id);
                                                    }}>
                                                        <img src={process.env.PUBLIC_URL + "/image/btn_delete_accompany_family.svg"}/>
                                                    </div>
                                                    }
                                                </AccompanyTitleWrap>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                trJsx
                            }
                        </tbody>
                    </FixedFormTable>
                    )
                }
            })
        )
    }
}
export default RenderFormTable