import styled from "styled-components"
import mixins, { media } from "../../styles/mixins";
import React, { useEffect, useRef, useState } from "react";

const RadioButton = styled.label`
    display: flex;
    align-items: center;
    gap: 0 6px;
    ${mixins.label}
    color: #858585;

    & ~ & {
        margin-left: 35px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        ${media.mobile`
            width: 14px;
            height: 14px;
        `}

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url("${process.env.PUBLIC_URL}/image/icn_radio.svg");
            background-size: auto 100%;
        }

        &:checked {
            &::before {
                background-position: -100%;
            }
            &::before {
                background-position: -100%;
            }
        }
            
        &:disabled {
            &::before {
                opacity: 0.5;
            }
        }
    }
`;

const CheckboxWrap = styled.label`
    display: flex;
    align-items: center;
    gap: 0 6px;
    ${mixins.label}
    color: #858585;

    input {
        border: none;
        outline: none;
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        ${media.mobile`
            width: 14px;
            height: 14px;
        `}

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url("${process.env.PUBLIC_URL}/image/icn_checkbox.svg");
            background-size: auto 100%;
        }

        &:checked {
            &::before {
                background-position: -100%;
            }
            &::before {
                background-position: -100%;
            }
        }
            
        &:disabled {
            &::before {
                opacity: 0.5;
            }
        }
    }
`;

const Text = styled.div`
    width: 100%;

    input {
        width: 100%;
        height: 52px;
        border: 1px solid;
        outline: none;
        padding: 12px 15px;
        box-sizing: border-box;
        font-family: 'NotoSans', sans-serif;
        border-color: #DBDBDB;
        ${mixins.label}

        ${(props) => {
            if (props.$datepicker) {
                return (
                    `
                    background-image: url(${process.env.PUBLIC_URL}/image/icn_calendar.svg); 
                    background-repeat: no-repeat;
                    background-position: 95% center;
                    ${props.$error ? `border-color: #ff0000;` : ""}
                    `
                )
            }
            if (props.$error) {
                return (
                    `
                    border-color: #ff0000 !important;
                    `
                )
            }
        }}

        ${media.mobile`
            height: 44px;
        `}
        
        &:focus {
            border-color: #000;
        }

        &:disabled {
            background-color: #f3f3f3;
            color: #858585;
        }
    }
`;

const FileWrap = styled.div`
    padding: 17px 0;
    width: 100%;
    
    ${media.mobile`
        padding: 0;
    `}
`;

const FileButton = styled.label`
    border-radius: 4px;
    padding: 7px 50px;
    text-align: center;
    ${mixins.label}
    color: ${props => props.disabled ? "rgba(133, 133, 133, 0.4)" : "#fff"};
    cursor: ${props => props.disabled ? "default" : "pointer"};
    background-color: ${props => props.disabled ? "#F3F3F3" : "#728190"};
    width: 240px;

    input {
        position: absolute;
        width: 0px;
        height: 0px;
        opacity: 0;
        clip-path: polygon(0 0, 0 0, 0 0);
    }

    ${media.mobile`
        width: 100%;    
    `}
`;

const FileName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #858585;
    border-color: ${props => props.$isError ? "#ff0000" : "#858585"};
    margin-top: 6px;
    color: #858585;
    ${mixins.label}
    padding: 13px; 
    opacity: ${props => props.$isEmpty ? 0.4 : 1};

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 25px);
    }

    .btn-delete {
        cursor: pointer;
        width: 15px;
        height: 15px;

        img {
            display: block;
            width: 100%;
        }
    }
`;

const FormGroup = styled.div`
    white-space: nowrap;
    width: 100%;

    
    .input-wrap {
        width: 100%;
        display: flex;
        gap: 0 5px;
        align-items: center;
        ${media.mobile`
            ${(props) => {
                    if (props.$check) {
                        return (
                            `
                            gap: 0 20px;
                            
                            `
                        )
                    }
                }
            }
        `}
        ${(props) => {
                if (props.$check) {
                    return (
                        `
                        flex-wrap: wrap;
                        gap: 0 40px;
                        
                        `
                    )
                }
            }
        }
    }

    .unit {
        width: 26px;
    }
`;

export const ErrorMsg = styled.p`
    ${mixins.caption};
    margin-top: 8px !important;
    color: #F90000;
`

const Form = () => {
    return (
        <form></form>
    )
}

const Group = ({isError="", error="", children, check=false}) => {
    return (
        <FormGroup $check={check} className={isError ? "error" : ""}>
            <div className="input-wrap">
                {children}
            </div>
            {error && <ErrorMsg>{error.message}</ErrorMsg>}
        </FormGroup>
    )
}

const Label = ({children}) => {
    return <label>{children}</label>
}

const Radio = ({children, label="", register={}, ...props}) => {
    return (
        <RadioButton>
            <input {...props} {...register} type="radio" />
            {label}
        </RadioButton>
    )
}

const Checkbox = ({children, label="", register={}, ...props}) => {
    return (
        <CheckboxWrap>
            <input {...props} {...register} type="checkbox" />
            {label}
        </CheckboxWrap>
    )
}
const Control = React.forwardRef(({children, label, datepicker=false, error="", isError=false, inputRef, ...props}, ref) => {
    if (datepicker) {
        return (
            <Text $error={isError ? true : false} $datepicker={true}>
                <input type="text" 
                    {...props}
                    ref={inputRef}
                />
                {
                    error &&
                    <ErrorMsg>{error.message}</ErrorMsg>
                }
            </Text>
        )
    } else {
        return (<Text $error={isError ? true : false}>
                <input type="text" 
                    {...props} 
                    ref={ref ? ref : null}
                />
                {
                    error &&
                    <ErrorMsg>{error.message}</ErrorMsg>
                }
            </Text>)
    }
})
const File = React.forwardRef(({children, label, disabled=false, isError, error, defaultValue,value, ...props}, ref) => {
    const [fileName, setFileName] = useState();
    const fileRef = useRef(); // 값 삭제용
    useEffect(() => {
        if (defaultValue) {
            setFileName(defaultValue)
        }
    });

    return (
        <FileWrap className="file-wrap">
            <FileButton disabled={disabled}>
                파일 업로드
                <input type="file" 
                    {...props}
                    ref={(e) => {
                        ref(e)
                        fileRef.current = e;
                    }}
                    disabled={disabled} 
                    onChange={(e) => {
                        props.onChange(e);
                        if (e.target.files[0]) {
                            setFileName(e.target.files[0].name);
                        } else {
                            setFileName("");
                        }
                    }}
                />
            </FileButton>

            <FileName $isEmpty={!fileName} $isError={isError}>
                <span>
                    {fileName ? fileName : '선택된 파일이 없습니다'}
                </span>

                {
                    fileName && 
                    <div className="btn-delete" onClick={() => {
                        props.onChange(null);
                        setFileName("");
                        fileRef.current.value = "";
                    }}>
                        <img src={process.env.PUBLIC_URL + "/image/btn_delete.svg"}/>
                    </div>
                }
            </FileName>
            {error && <ErrorMsg>{error.message}</ErrorMsg>}
        </FileWrap>
    )
})

Form.Label = Label;
Form.Radio = Radio;
Form.Checkbox = Checkbox;
Form.Group = Group;
Form.Control = Control;
Form.File = File;
export default Form;