import styled from "styled-components"
import mixins, { media } from "../../styles/mixins";

export const Wrap = styled.div`
    display: flex;
    padding-top: 40px;
    gap: 0 10px;
    width: 100%;
    
    // table
    table {
		table-layout: fixed;
        width: 100%;
        // thead
        thead {
            th {
                position: relative;
                ${mixins.fontStyle("24px", "600", "32.7px")}
                color: #000;
                padding: 8px 0 18px;
                
                &.required {
                    span {
                        &::after {
                            content: "*";
                            color: #ff0000;
                        }
                    }
                }
                
                &:last-of-type {
                    &::after {
                        width: 100%;
                    }
                }
                
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: calc(100% - 10px);
                    height: 3px;
                    background-color: #000;
                }
            }
        }
        // thead

        // tbody
        tbody {
            th {
                ${mixins.fontStyle("18px", "600", "24.52px")}
                color: #000;
                padding: 28px 0;
                border-bottom: 1px solid #EFEFEF;
                vertical-align: middle;


                &.required {
                    span {
                        &::after {
                            content: "*";
                            color: #ff0000;
                        }
                    }
                }
            }
            td {
                ${mixins.label}
                color: #858585;
                padding: 17px 0;
                border-bottom: 1px solid #EFEFEF;
                vertical-align: middle;
                
                &.top {
                    vertical-align: top;
                }

                > div {
                    height: 100%;
                }
                
                ${media.desktop`
                    padding-right: 10px;    
                    &:last-of-type {
                        padding-right: 0;
                    }
                `}

                &.empty {
                    text-align: center;
                    div {
                        margin: 0 auto;
                        width: 272px;
                        text-align: center;
                    }
                }

            }

            p {
                margin-top: 12px;
                & ~ p {
                    margin-top: 6px;
                }
            }

            .caption {
                ${mixins.caption}
                color: #06315B;
            }

            .description {
                ${mixins.description}
                color: rgba(0, 0, 0, 0.4);
            }
        }
        // tbody
    }
    // table

    // mo
    ${media.mobile`
        flex-direction: column;
        .mo {
            display: flex;
            flex-direction: column;
            gap: 10px 0;
            background-color: #E8E8E8;
        }
        table {
            tr {
                background-color: #fff;
            }
            
            thead {
                th {
                    padding: 14px 20px;
                    ${mixins.fontStyle("16px", "600", "21.8px")}
                }
            }
            tbody {
                tr {
                    &.border-none {
                        th, td {
                            border-bottom: none;
                        }
                    }

                    &.desc {
                        td {
                            padding: 0 20px 20px !important;
                        }
                    }

                    th {
                        width: 140px;
                        padding: 23px 20px;
                        ${mixins.fontStyle("14px", "600", "19px")}

                        &:first-child:last-child {
                            padding: 20px 20px 0;
                        }
                    }

                    td {
                        padding: 10px 20px 10px 0;

                        &:first-child:last-child {
                            padding: 20px;
                        }

                        p {
                            margin-top: 0;

                            & ~ p {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    `}
    // mo
`;
export default function FormTable({children, ...props}) {
    return (
        <Wrap {...props}>
            <table>
                {children}
            </table>
        </Wrap>
    )
}