import { useFormContext } from "react-hook-form"
import React from "react";
import { useSelector } from "react-redux";

const useApplicationFormUitils = () => {
    const form = useFormContext();
	/* 
	customersInfo 개수에 따른 조합
		1. 1개이면 단독사업자 + 비사업배우자 조합
		2. 2개이면 공동사업자1 + 공동사업자2 조합
	*/
	const customersInfo = useSelector(state => state.customers).value;


        /**
     * 항목 설명
     * @param {string} name 항목 이름
     * @return {*} 
     */
    const getCategoryDescription = (name) => {
        switch (name) {
            case "join_attachment":
                return (
                    <>
                        <p className="description">
							자택으로 초대장과 함께 발송드렸던 <br className="pc"/>비사업배우자 참가신청서 작성 후 <br/>첨부 부탁드립니다.
                        </p>
                    </>
                )
            case "safety_check":
                return (
                    <>
                        <p className="description">
							자택으로 지류 발송드렸던 ‘안전확인 서약서’에 <br className="mo"/>‘해당사항 있음’으로 체크한 항목이 있습니까? <br className="mo"/>(임산부,장애, 지병 및 기타 질병)
                        </p>
                        <p className="caption">
                            * 해당 사항이 있는 경우 반드시 상세 내용을 동의서 상에 
                            <br className="pc"/> 작성하여 주시기 바랍니다.
                        </p>
                    </>
                )
            case "allergy":
                return (
                    <>
                        <p className="description">
                            알러지 또는 유의해야 할 식단이 있습니까?
                            <br/>있다면 어떤 것인지 적어주십시오.
                        </p>
                    </>
                )
            case "family_attachment": 
                return (<>
					<p className="caption" style={{color: "#ff0000"}}>
						* 비사업 배우자 또는 동반가족 참여 시 필히 제출 바랍니다.
					</p>
                    <p className="caption">
                        * 가족관계증명서 등 서류 검토 후 사실 관계 입증이 불가한
                        <br/>대상자는 참가할 수 없습니다.
                    </p>
                    <p className="caption">
                        * 2촌 이상 가족이 참석하시어 가족 관계를 입증할 서류가
                        <br/>여러 장일 경우, 서류를 모두 한 번에 촬영하시어 1장으로
                        <br/>등록 부탁 드립니다.
                    </p>
                </>)
        }
    }

    

	/**
	 * 내국인 구분에 따라 주민등록번호 or 외국인등록번호 항목명
	 * @param {string} form1
	 * @param {string} form2
	 * @param {string} name
	 * @return {string} 
	 */
	const getRegistrationTitle = (type1, type2) => {
		let title1 = "";
		let title2 = "";

		if (type1 == "외국인") {
			title1 = "외국인등록번호"
		} else {
			title1 = "주민등록번호"
		}
		
		if (type1 == type2) {
			return title1;
		} else {
			if (type2 == "외국인") {
				title2 = "외국인등록번호"
			} else {
				title2 = "주민등록번호"
			}
		}

		if (type1 && type2) {
			return title1 + "/" + title2
		}
		if (type1 && !type2) {
			return title1;
		}
		if (!type1 && type2) {
			return title2;
		}

		return title1;
	}

    
	/**
	 * 같은 행 항목에 오류가 있는 input이 있는지 체크
	 * @param {string} form1 첫 번째 폼 이름
	 * @param {string} form2 두 번째 폼 이름
	 * @param {string} name 항목명
	 * @return {boolean} 
	 */
	const checkErrorCategory = (form1, form2, name) => {
		const error1 = form.getFieldState(form1, form.formState).error;
		const error2 = form.getFieldState(form2, form.formState).error;
		if (error1) {
			if (name in error1) {
				return true;
			}
		}
		if (error2) {
			if (name in error2) {
				return true;
			}
		}
	}

    
	/**
	 * 전체 항목 합치기 및 중복 제거 후 items1, 2, 3 사용 유무 구분
	 * @param {array} [uniqueItem=[]] 값을 담을 배열 (반복문 안에서 함수를 호출하므로 반복문 바깥에서 배열을 초기화할 것)
	 * @param {object} item 항목
	 * @return {array} 
	 */
	const mergeObj = (arr1 = [], arr2 = []) => {
		const mergedArr = [];
		const seenNames = new Set(); // 중복을 체크하기 위한 Set

		const defaultArr = arr1.length > arr2.length ? arr1 : arr2;
		const addItems = (item) => {
			const useItem1 = arr1.filter(v => v.name == item.name)[0]?.is_show == "true" ? true : false;
			const useItem2 = arr2.filter(v => v.name == item.name)[0]?.is_show == "true" ? true : false;
			if (useItem1 || useItem2) {
				if (!(item.name === "join_attachment" && customersInfo.length === 2)) { // 공동 사업자일 때 비사업배우자 참가신청서 제외
					seenNames.add(item.name);
					mergedArr.push({
						...item,
						used_in_items1: useItem1,
						used_in_items2: useItem2,
					});
				}
			}
		}

		defaultArr.forEach((item, idx) => {
			addItems(item);
		});

		// 첫 번째 배열의 요소 중에서 중복되지 않은 항목만 추가
		arr1.forEach(item => {
			if (!seenNames.has(item.name)) {
				addItems(item);
			}
		});

		// 두 번째 배열의 요소 중에서 중복되지 않은 항목만 추가
		arr2.forEach(item => {
			if (!seenNames.has(item.name)) {
				addItems(item);
			}
		});

		return mergedArr;
	}

    return { 
        getCategoryDescription, 
        getRegistrationTitle, 
        checkErrorCategory,
        mergeObj,
    }
}
export default useApplicationFormUitils;