import styled from "styled-components";
import FormTitle from "../../organisms/FormTitle";
import PageTemplate from "../../templates/PageTemplate";
import Caption from "../../atoms/Caption";
import Button from "../../atoms/Button";
import FormTable, { Wrap as FormTableWrap } from "../../organisms/FormTable";
import Form from "../../atoms/Form";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import mixins, { media } from "../../../styles/mixins";
import { useActivityFormQuery } from "../../../hooks/api/useActivityForm";
import { FormProvider, useForm } from "react-hook-form";
import GetInput from "../../molecules/GetInput";
import { useActivityMutation } from "../../../hooks/api/useApplicationActivity";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../organisms/Error";
import { useApplicationQuery } from "../../../hooks/api/useApplication";
import { hideAlert, showAlert } from "../../../reducer/alertSlice";
import useDeviceSize from "../../../hooks/useDeviceSize";
import Loading from "../../organisms/Loading";
import Modal from "../../organisms/Modal";

const Container = styled.div`
    display: none;

    &.show {
        display: block;
    }

    .empty {
        padding-top: 60px;

        p {
            ${mixins.fontStyle("18px", "700", "24.52px")};
            color: #000;
        }
    }

    ${media.mobile`
        & ~ & {
            border-bottom: 1px solid rgba(173, 173, 173, 1);
        }
        .empty {
            padding: 30px 20px 0;

            p {
                ${mixins.fontStyle("14px", "700", "19.07px")};
                color: #000;
            }
        }
    `}
`
const Section = styled.section`
    // 액티비티 선택사항 입력
    &.activity-options {
        > p {
            ${mixins.fontStyle("18px", "700", "24.52px")}
            color: #000;
            margin-top: 30px;
        }
        .user-wrap {
            & ~ .user-wrap {
                margin-top: 60px;
            }
            ${FormTableWrap} {
                background-color: #F6F6F6;
                flex-direction: column;
                padding-top: 0;
                margin-top: 40px;
                gap: 10px 0;
    
                & ~ ${FormTableWrap} {
                    padding-top: 10px;
                }
                
                table {
                    background-color: #fff;
                    td, th {
                        border-color: rgba(173, 173, 173, 1);
                    }

                    th {
                        word-break: keep-all;

                        &.required {
                            ::after {
                                content: " *필수";
                            }
                        }
                    }
                    
                    tbody {
                        tr {
                            &.question {
                                th {
                                    border: 0;
                                    padding-bottom: 0;
                                }

                                & + tr {
                                    td {
                                        padding: 27px 0;
                                    }
                                }
                            }
                        }
                        th {
                            padding-right: 28px;
                        }
                    }
                }
            }
        }
    }
    // 액티비티 선택사항 입력

    .activity {
        table {
            tr {
                &.disabled {
                    background-color: #F3F3F3F3;
                    opacity: 0.7;
                }
                td {
                    padding-left: 20px;
                }
            }
        }
    }
    
    & ~ & {
        margin-top: 120px;
    }

    .caption-wrap {
        padding: 30px 27px 40px;
    }

    .btn-activity-info {
        margin-top: 60px;
    }

    ${media.mobile`
        & ~ & {
            margin-top: 60px;
        }

        // 액티비티 선택사항 입력 (mo)
        &.activity-options {
            background-color: #E8E8E8;

            > p {
                ${mixins.fontStyle("14px", "700", "19.6px")}
                padding: 20px;
                background-color: #fff;
                margin-top: 0;
            }

            .user-wrap {
                & ~ .user-wrap {
                    margin-top: 10px;
                }

                ${FormTableWrap} {
                    margin-top: 0;
                    gap: 6px 0;
    
                    & ~ ${FormTableWrap} {
                        padding-top: 5px;
                    }

                    table {
        
                        tbody {
                            th {
                                padding-right: 20px !important;
                                padding-bottom: 20px;
                            }
    
                            tr {
                                &.question {
                                    & ~ tr {
                                        td {
                                            padding: 10px 20px 18px;
                                        }
                                    }
                                }

                                &:last-child {
                                    td {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // 액티비티 선택사항 입력 (mo)
        
        table thead th {
            &::after {
                width: 100% !important;
            }
        }

        .caption-wrap {
            padding: 20px;
        }

        .btn-activity-info {
            margin: 30px 20px;
            width: calc(100% - 40px);
        }

    `}
`
const ImgWrap = styled.div`
    width: 100%;

    img {
        display: block;
        width: 100%;
    }
`
const TableWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 60px 40px;

    > div {
        display: block;
        width: calc(50% - 20px);
    }

    // table
    table {
        tbody {
            tr {
                th {
                    padding: 18px 0;
                    &:first-child {
                        width: 480px;
                        
                        & + th {
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
    // table

    ${media.mobile`
        flex-direction: column;
        background-color: #E8E8E8;
        gap: 10px 0;
        
        > div {
            width: 100%;
            background-color: #fff;
        }

        ${FormTableWrap} {
            padding-top: 10px;
        }

        // table (mo)
        table {
            tr {
                th, td {
                    padding: 15px 20px !important;
                }
            }
            tbody {
                tr {
                    th {
                        &:first-child {
                            width: 260px;
                        }
                    }
                }
            }
        }
        // table (mo)

    `}
`
const ButtonCont = styled.div`
    display: flex;
    gap: 0 40px;
    margin-top: 120px;
    ${media.mobile`
        padding: 0 20px;
        margin-top: 60px;
        gap: 0 20px;
    `}
`

export default function ActivityForm({ scheduleIdx, applicationIdx, target, urlPath }) {
    const navigate = useNavigate();
	const customersInfo = useSelector(state => state.customers).value;

    const activityFormQuery = useActivityFormQuery(scheduleIdx);
    const activityMutation = useActivityMutation();
    const applicationQuery = useApplicationQuery({applicationIdx, scheduleIdx});
    const [defaultV, setDefaultV] = useState();
    
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(0);
    const [allActivity, setAllActivity] = useState({}); // activity_idx 기준 횟수 제한
    const [allSchedule, setAllSchedule] = useState({}); // activity_schedule_idx 기준 잔여 좌석 정보
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sortActivityData, setSortActivityData] = useState([]); // Day 정렬
    const formRef = useRef();

    const { isMobile } = useDeviceSize();
    const dispatch = useDispatch();
    
    const form = useForm({
        mode: "all",
        shouldUnregister: true, // DOM에서 input 언마운트시 필드 제거
        values: defaultV
    });

    const checkActivityCount = () => {
        let isValid = true;
        let counter = {}; // 횟수 제한
        let counterForSeats = {}; // 잔여 좌석
        
        // 1. 양식에서 선택된 값
        console.log("activity", form.getValues("activity"));
        Object.keys(form.getValues("activity")).forEach((currentUser, userCnt) => {
            const el = form.getValues("activity")[currentUser];
            const userIdx = el.user_idx;
            const userActivityList = el.info;
            // 2. 선택된 액티비티 횟수, 해당 액티비티 제한, 해당 액티비티 타이틀 객체로 생성 
            userActivityList?.forEach(list => {
                const key = list.activity_idx;
                counter[key] = counter[key] ? counter[key]+1 : 1;
            });

            userActivityList?.forEach(list => {
                const value = list.activity_schedule_idx;
                const day = Object.keys(el).find(key => el[key] == value && key != "user_idx");
                // 기존 신청했던 액티비티는 카운트에서 제외
                if (defaultV.activity[`user${userIdx}`]) {
                    if (defaultV.activity[`user${userIdx}`][day] != value) {
                        counterForSeats[value] = counterForSeats[value] ? counterForSeats[value]+1 : 1;
                    }
                } else {
                    counterForSeats[value] = counterForSeats[value] ? counterForSeats[value]+1 : 1;
                }
            });
        });

        // 4. 제한 없는 항목을 제외하고 초과할 경우 알림창 (limit -1일 경우 무제한)
        for (const key in counter) {
            console.log(allActivity);
            if ((allActivity[key].limit !== -1 && allActivity[key].limit !== "") && counter[key] > allActivity[key].limit) {
                isValid = false;
                alert(`${allActivity[key].title} 액티비티는 ${allActivity[key].limit}회만 신청 가능한 액티비티입니다.`);
            }
        }
        
        // 5. 현재 인원에서 선택된 액티비티 잔여좌석 초과할 경우 알림창
        for (const key in counterForSeats) {
            if (allSchedule[key].remaining_seats !== -1 && counterForSeats[key] > allSchedule[key].remaining_seats) {
                isValid = false;
                alert(allSchedule[key].day + " " + allSchedule[key].title + " 액티비티의\n 잔여 좌석이 없어 신청이 불가합니다.\n다른 액티비티로 선택해주세요.")
            }
        }

        console.log("counterLimit", counter);
        console.log("counterSeats", counterForSeats);
        if (isValid) {
            setStep(step+1);
            window.scrollTo(0, 0);
            form.clearErrors("form");
        }
    }

    const onSubmit = () => {
        if (step == 1) {
            // 신청하기
            const formData = {
                abo_number: customersInfo[0].uid,
                activity: [],
                form: []
            };
    
            // 선택된 액티비티
            if (form.getValues("activity")) {
                Object.keys(form.getValues("activity")).forEach((currentUser, idx) => {
                    const el = form.getValues("activity")[currentUser];
                    if (!formData['activity'][idx]) {
                        formData['activity'][idx] = {
                            lists: [],
                        };
                    }
                    // userIdx 
                    if (el.user_idx) {
                        formData['activity'][idx]['user_idx'] = parseInt(el.user_idx);
                        // 액티비티 선택 항목 activity_schedule_idx
                        for (const key in el) {
                            if (key !== "info" && key !== "user_idx") {
                                formData['activity'][idx]['lists'].push(parseInt(el[key]));
                            }
                        }
                    }
                })
            }
            if (form.getValues("form")) {
                Object.keys(form.getValues("form")).forEach((currentUser, idx) => {
                    const el = form.getValues("form")[currentUser];
                    if (el.user_idx) {
                        formData.form.push({
                            user_idx: el.user_idx,
                            items: []
                        })
                        for (const activityIdx in el.items) {
                            formData['form'][idx]['items'].push(el.items[activityIdx])
                        }
                    }
                })
            }
            console.log("data", formData);
            formData['event_schedule_idx'] = parseInt(scheduleIdx);
            let payload = {};
            if (isSubmit) { // 수정
                payload = {formData, applicationIdx};
            } else { // 신청
                payload = {formData};
            }
            
            setIsLoading(true);
            activityMutation.mutate(payload, {
                onSuccess: (data) => {
                    navigate("/application/complete/" + data.data + "/" + scheduleIdx); // 신청 상세로 이동
                },
                onError: (error) => {
                    setIsLoading(false);
                    dispatch(showAlert({
                        msg: error.response?.data?.msg?.ko ? error.response.data.msg.ko : "오류가 발생했습니다.",
                        func: () => {
                            if (error.response.data.code == -16 || error.response.data.code == -17) {
                                setStep(0);
                            }
                            dispatch(hideAlert())
                        }
                    }));
                    activityFormQuery.refetch();
                },
                onSettled: () => {
                    setIsLoading(false);
                }
            });
        } else {
            checkActivityCount();
        }
    }

    const onError = () => {
        if (step == 0) {
            // 액티비티 선택에서 오류 없을 경우
            if (!form.formState?.errors.activity || form.formState?.errors?.activity?.length == 0) {
                // 잔여좌석, 횟수 제한 체크
                checkActivityCount();
            } else {
                dispatch(showAlert({
                    msg: "필수 항목을 확인해주세요"
                }))
            }
        } else {
            dispatch(showAlert({
                msg: "필수 항목을 확인해주세요"
            }))
        }
    }

    useEffect(() => {
        if (applicationIdx) {
			applicationQuery.refetch();
        }
    },[applicationIdx])

    useEffect(() => {
        if (activityFormQuery.data) {
            const tempObj = {};
            const tempObj2 = {};

            for (const day in activityFormQuery.data.activity) {
                const activityObj = activityFormQuery.data.activity[day];
                for (const userIdx in activityObj) {
                    const activityList = activityObj[userIdx];
                    activityList.forEach(activity => {
                        tempObj[activity.activity_idx] = {
                            limit: activity.limit,
                            title: activity.title,
                        }

                        tempObj2[activity.activity_schedule_idx] = {
                            remaining_seats: activity.remaining_seats,
                            title: activity.title,
                            day: day
                        }
                    });
                }
                
            }
            
            setAllActivity(tempObj);
            setAllSchedule(tempObj2);

            const sortData = Object.keys(activityFormQuery.data.activity)?.sort((a, b) => {
                // "day" 뒤의 숫자를 추출하여 숫자로 변환
                const numA = parseInt(a.replace("day", ""), 10);
                const numB = parseInt(b.replace("day", ""), 10);
                return numA - numB; // 숫자를 기준으로 정렬
            })
            setSortActivityData(sortData);
        }
    }, [activityFormQuery.data]);

    // 수정 시 이전 입력 정보 불러오기
    useEffect(() => {
        if (applicationQuery.data) {
            let resetData = {
                form: [],
                activity: {

                }
            };
            let userCnt = 0;
            applicationQuery.data?.application?.forEach((user) => {
                if (user.fixed_form_value.filter(v => v.name === "is_join")[0]?.value == "참여") {
                    user.activity.forEach(activity => {
                        activity.lists.forEach(activityDetail => {
                            if (activityDetail.is_select) {
                                if (!resetData['activity'][`user${user.idx}`]) {
                                    resetData['activity'][`user${user.idx}`] = {}
                                }
                                resetData['activity'][`user${user.idx}`][`day${activity.day}`] = activityDetail.activity_schedule_idx.toString()
                            }
                        })
                    })
                    if (user.my_activity.length > 0) {
                        setIsSubmit(true);

    
                        user.my_activity.forEach((activity) => {
                            if (!resetData['form'][`user${user.idx}`]) { // 선택 사항 입력 폼
                                resetData['form'][`user${user.idx}`] = {
                                    user_idx: user.idx,
                                    items: []
                                }
                            }
    
                            // 액티비티 선택 정보
    
                            activity.activity_form_value.forEach((formValue, formIdx) => {
                                if (!resetData['form'][`user${user.idx}`]['items'][`activity${activity.activity_idx}`]) {
                                    resetData['form'][`user${user.idx}`]['items'][`activity${activity.activity_idx}`] = {
                                        activity_idx: activity.activity_idx,
                                        value: {}
                                    }
                                }
                                if (formValue.type === "file") {
                                    resetData['form'][`user${user.idx}`]['items'][`activity${activity.activity_idx}`]['value'][formValue.name] = {
                                        type: "file",
                                        value: formValue?.value
                                    }
                                } else {
                                    resetData['form'][`user${user.idx}`]['items'][`activity${activity.activity_idx}`]['value'][formValue.name] = formValue.value;
                                }
                            })
    
                        })
                    }
                    userCnt ++;
                }
            });

            console.log("신청 정보", resetData);
            setDefaultV(resetData);
        }
    }, [applicationQuery.data]);
    

    // 신청기간 아닐 경우 초기 화면으로 이동
    if (target != "activity") {
        alert("2차 신청기간이 아닙니다.")
        return <Navigate to={"/application/"+urlPath} />
    }

    if (activityFormQuery.isLoading || applicationQuery.isLoading) {
        return (
            <Loading layer={true} />
        )
    }
    
    if (activityFormQuery.isError) {
        return (
            <Error msg={activityFormQuery.error.response?.data.msg.ko ? activityFormQuery.error.response?.data.msg.ko : "오류가 발생했습니다."}/>
        )
    }

    if (activityFormQuery.data) {
        return (
            <FormProvider {...form}>
                <form ref={formRef} onSubmit={form.handleSubmit(onSubmit, onError)}>
                    <Container className={step == 0 ? "show" : ""}>
                        <Section>
                            <FormTitle>액티비티 예약</FormTitle>

                            <div className="caption-wrap">
                                <Caption color="#ff0000">
                                    * 액티비티 별 잔여인원은 제출 시간에 따라 차이가 있을 수 있습니다.
                                </Caption>
                            </div>

                            {
                                activityFormQuery.data.guide.guide_img_url &&
                                <ImgWrap>
                                    <img src={activityFormQuery.data.guide.guide_img_url} />
                                </ImgWrap>
                            }

                            {
                            activityFormQuery.data.guide.popup_img_url && 
                            <Button type="button" $bg="red" className="btn-activity-info" onClick={() => {
                                if (activityFormQuery.data.guide.popup_img_url) {
                                    setShowModal(true);
                                }
                            }}>
                                {activityFormQuery.data.guide.btn}
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="16.7782" y="11.8076" width="1" height="12" transform="rotate(45 16.7782 11.8076)" fill="white"/>
                                <rect x="17.4853" y="12.5147" width="1" height="12" transform="rotate(135 17.4853 12.5147)" fill="white"/>
                                </svg>
                            </Button>
                            }
                        </Section>

                        {
                        sortActivityData?.length > 0
                        ? 
                        // 정렬된 Day 기준으로 Activity 출력
                        sortActivityData?.map((day, dayIdx) => {
                            const userList = activityFormQuery.data.activity[day];

                            return (
                            <Section key={`activity-section-${day}`}>
                                <FormTitle>{day} 예약</FormTitle>
                                
                                <TableWrap>
                                    {
                                        Object.keys(userList).map((userIdx, userCnt) => {
                                            const activityList = userList[userIdx];

                                            return (
                                                <FormTable className="activity" key={`activity-table-${day}-${userCnt}`}>
                                                    <thead>
                                                        <tr>
                                                            <th className="required" colSpan={2}>
                                                                <span>
                                                                    {activityFormQuery.data.user_group[userIdx]} 액티비티
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>
                                                                액티비티명
                                                            </th>
                                                            <th>
                                                                잔여 좌석
                                                            </th>
                                                        </tr>
                                                        {
                                                            activityList.map((activityItem, activityItemIdx) => {
                                                                const inputName = `activity.user${userIdx}.${day}`;
                                                                const scheduleIdx = form.watch(inputName); // 액티비티 선택 값
                                                                let defaultValue;
                                                                // active_idx form에 추가
                                                                if (activityItem.activity_schedule_idx == scheduleIdx) {
                                                                    form.setValue(`activity.user${userIdx}.info.${dayIdx}.activity_idx`,activityItem.activity_idx);
                                                                    form.setValue(`activity.user${userIdx}.info.${dayIdx}.activity_schedule_idx`,activityItem.activity_schedule_idx);
                                                                    form.setValue(`activity.user${userIdx}.info.${dayIdx}.activity_title`,activityItem.title);
                                                                }
                                                                if (defaultV) {
                                                                    if (defaultV['activity'][`user${userIdx}`]) {
                                                                        defaultValue = defaultV['activity'][`user${userIdx}`][day];
                                                                    }
                                                                }

                                                                const isDisabled = activityItem.remaining_seats === 0 && activityItem.activity_schedule_idx != defaultValue;

                                                                return (
                                                                    <tr key={`activity-table-tr-${day}-${userIdx}-${activityItemIdx}`} className={isDisabled ? "disabled" : ""}>
                                                                        <td>
                                                                            <input type="hidden" {...form.register(`activity.user${userIdx}.user_idx`)} value={userIdx} />
                                                                            <Form.Group isError={form.getFieldState(inputName).error} error={form.getFieldState(inputName).error}>
                                                                                <Form.Radio 
                                                                                    register={form.register(inputName, {
                                                                                        required: "필수 항목을 선택하세요",
                                                                                    })}
                                                                                    checked={isDisabled ? false : undefined}
                                                                                    value={activityItem.activity_schedule_idx} 
                                                                                    label={`${activityItem.title} ${activityItem.start_time ? `(${activityItem.start_time})` : ""}`} 
                                                                                    disabled={isDisabled}
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                        <td>
                                                                            {activityItem.remaining_seats == -1 ? "-" : activityItem.remaining_seats}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </FormTable>
                                                
                                            )
                                        })
                                    }
                                </TableWrap>
                            </Section>
                            )
                        })
                        :
                        <div className="empty">
                            <p>
                                등록된 액티비티가 없습니다
                            </p>
                        </div>
                        }
                    </Container>

                    
                    {
                    <Container className={step == 1 ? "show" : ""}>
                        <Section className="activity-options">
                            <FormTitle>액티비티 세부 문항 입력</FormTitle>
                            <p>
                                동일 액티비티를 여러번 선택하신 경우 세부 문항은 1회만 수집하오니, 선택하신 액티비티 전체 내역 확인을 원하시는 경우 액티비티 선택하기(이전 단계) 혹은 신청 완료 후 확인 가능합니다.
                            </p>
                            {
                            Object.keys(activityFormQuery.data.activity_form).map((userIdx, userCnt) => {
                                const activityFormList = activityFormQuery.data.activity_form[userIdx];
                                let showActivityCnt = -1;

                                return (
                                    <div className="user-wrap" key={`activity-optios-table-${userIdx}`}>
                                        {
                                            isMobile ?
                                            <colgroup style={{width: "140px"}} />
                                            :
                                            <colgroup style={{width: "370px"}} />
                                        }
                                        <input type="hidden" {...form.register(`form.user${userIdx}.user_idx`, {
                                            value: parseInt(userIdx)
                                        })} />

                                        {
                                        Object.keys(activityFormList).map((activityIdx, activityCnt) => {
                                            const formObj = activityFormList[activityIdx];
                                            // 선택된 항목인지 체크
                                            if (form.getValues(`activity.user${userIdx}.info`)?.findIndex(v => v?.activity_idx == activityIdx) > -1) {
                                                showActivityCnt++;
                                                return (
                                                    <FormTable key={`activity-form-user-${userIdx}-${activityIdx}`}>
                                                        <colgroup>
                                                            {
                                                                isMobile ?
                                                                <col style={{width: '140px'}}/>
                                                                :
                                                                <col style={{width: '370px'}}/>
                                                            }
                                                        </colgroup>
                                                        <input type="hidden" 
                                                            {...form.register(`form.user${userIdx}.items.${`activity${activityIdx}`}.activity_idx`, {
                                                                value: parseInt(activityIdx)
                                                            })} 
                                                        />
                                                        <thead>
                                                            {
                                                                showActivityCnt == 0 &&
                                                                <tr>
                                                                    <th colSpan={2}>
                                                                        {activityFormQuery.data.user_group[userIdx]}
                                                                    </th>
                                                                </tr>
                                                            }
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                <>
                                                                <tr key={`activity-form-tr-${activityIdx}`}>
                                                                    <th>
                                                                        선택 액티비티
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            formObj.title
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    formObj.form.length > 0 ?
                                                                    formObj.form.map((formItem, formItemIdx) => {
                                                                        return (
                                                                            <React.Fragment key={`activity-form-${userIdx}-${activityIdx}-item-${formItem.name}-${formItem.title}`}>
                                                                                <tr className="question">
                                                                                    <th className={formItem.is_required ? "required" : ""} colspan={2}>
                                                                                        <span>
                                                                                            {
                                                                                                formItem.title.split("\n")?.map((el, idx) => {
                                                                                                    if (formItem.title.split("\n").length-1 == idx) {
                                                                                                        return el

                                                                                                    } 
                                                                                                    return <>{el}<br/></>
                                                                                                })
                                                                                            }
                                                                                        </span>
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={2}>
                                                                                        {
                                                                                            <GetInput 
                                                                                                form={form}
                                                                                                type={formItem.type} 
                                                                                                name={formItem.name}
                                                                                                uniqueName={`form.user${userIdx}.items.${`activity${activityIdx}`}.value`}
                                                                                                option={JSON.stringify(formItem.options)}
                                                                                                isRequired={formItem.is_required}
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <th colSpan={2}>
                                                                            <input type="hidden" {...form.register(`form.user${userIdx}.items.${`activity${activityIdx}`}.value`, { value: "" })} />
                                                                            선택하신 액티비티는 세부 문항이 없습니다.
                                                                        </th>
                                                                    </tr>
                                                                }
                                                                </>
                                                            }
                                                        </tbody>
                                                    </FormTable>
                                                )
                                            }
                                        })
                                        }
                                    </div>
                                )
                            })
                            }
                        </Section>
                    </Container>
                    }

                    <ButtonCont>
                        <Button 
                            type="button"
                            onClick={() => {
                                if (step == 0) {
                                    navigate(`/application/${urlPath}/modify`);
                                } else {
                                    setStep(step-1);
                                }
                                window.scrollTo(0, 0);
                            }}
                        >
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="7.22186" y="13.2218" width="1" height="12" transform="rotate(-135 7.22186 13.2218)" fill="#06315B"/>
                            <rect x="6.51475" y="12.5148" width="1" height="12" transform="rotate(-45 6.51475 12.5148)" fill="#06315B"/>
                            </svg>
                            {step == 0 ? "신청 정보 수정하기" : "이전"}
                        </Button>
                        {
                        Object.keys(activityFormQuery.data.activity)?.length > 0 &&
                            <Button $bg="dark">
                                {
                                step < 1 ?
                                <>
                                    다음
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="16.7782" y="11.8076" width="1" height="12" transform="rotate(45 16.7782 11.8076)" fill="white"/>
                                    <rect x="17.4853" y="12.5147" width="1" height="12" transform="rotate(135 17.4853 12.5147)" fill="white"/>
                                    </svg>
                                </>
                                : "신청하기"
                                }
                            </Button>
                        }
                    </ButtonCont>
                </form>

                {
                    isLoading &&
                    <Loading layer={true} />
                }
                
                <Modal title={activityFormQuery.data.guide?.btn}  content={<img src={activityFormQuery.data.guide?.popup_img_url} />} hide={()=>{setShowModal(false)}} show={showModal} />
            </FormProvider>
        )
    }
}